export default {
  heading: 'Antique Olive Bold, sans-serif',
  body: 'Arial, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Roboto Condensed, sans-serif',
  googleFonts: ['Roboto Condensed'],
  customFamilies: ['Antique Olive Bold'],
  customUrls: [
    'https://www.gonation.biz/fonts/antique-olive/antique-olive.css',
  ],
}
